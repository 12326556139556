import { CustomTable } from '../../components/table';
import { getDropDownValues } from '../../services/ReportAPIService';
import { getState } from '../../store';
import { IntlProvider } from 'react-intl';
import { LoginState } from '../../store/slices/login/type';
import { orgParenting, log } from '../../utils/util';
import { userPermission } from '../../services/AuthAPIService';
import Box from '@mui/material/Box';
import BoxContainer from './components/boxContainer';
import Header from './components/header';
import React, { Fragment, useEffect } from 'react';
import FilterMenu from './components/filterMenu';

export const VOBReverifyUnable = (props: any) => {
  //1st row
  const [locationProp, setLocationProp] = React.useState([]);
  const [name, setName] = React.useState([]);
  const [verified, setVerified] = React.useState(['']);
  const [owner, setOwner] = React.useState([]);
  const [mrn, setMrn] = React.useState([]);
  const [count, setCount] = React.useState(0);

  //drops
  const [verifiedDrop, setVerifiedDrop] = React.useState(['']);
  const [ownerDrop, setOwnerDrop] = React.useState([]);

  const [expanded, setExpanded] = React.useState(false);

  const [loading, setLoading] = React.useState(false);

  const session: LoginState = getState().loginReducer;

  let columns: any = [
    { label: 'Patient Name', accessor: 'FullName', sortable: true, width: '200px' },
    { label: 'DOB', accessor: 'DOB', sortable: true, width: '120px' },
    { label: 'Address', accessor: 'Address1', sortable: true, width: '100px' },
    { label: 'City', accessor: 'City', sortable: true, width: '100px' },
    { label: 'State', accessor: 'State', sortable: true, width: '100px' },
    { label: 'Primary Insurance', accessor: 'Primary Insurance', sortable: true, width: '100px' },
    { label: 'Primary ID Number', accessor: 'Primary ID Number', sortable: true, width: '108px' },
    { label: 'Location', accessor: 'OrganizationName', sortable: true, width: '150px' },
    { label: 'NP Visit Scheduled', accessor: 'NP Visit Scheduled', sortable: true, width: '100px' },
    { label: 'NP Visit-Date', accessor: 'NP Visit-Date', sortable: true, width: '120px' },
    { label: 'Established Restart Date', accessor: 'Established Restart', sortable: true, width: '130px' },
    // { label: 'Customer Id', accessor: 'CustomerId', sortable: true, width: '100px' },
    { label: 'Username', accessor: 'Username', sortable: true, width: '300px', actionEvent: props.onClickCopy }
  ];

  function retColumns() {
    if (currentPage() == 'Reverify') {
      columns.splice(7, 0, { label: 'Expiration of Benefits Date', accessor: 'Expiration of Benefits Date', sortable: true, width: '133px' });
    }
    return columns;
  }

  // table
  props.tablePars.columns = retColumns();

  let kindBoxes1 =
  {
    type: 'Joy',
    boxes: [
      { name: 'Location', array: orgParenting(), value: locationProp, setValue: setLocationProp, handle: props.handleParenting, accessor: 'OrganizationName', type: 'multiSelectGroup' },
      { name: 'Patient Name', value: name, setValue: setName, handle: props.handleMul, accessor: 'FullName', type: 'textField' },
      { name: 'Insurance Verified', array: verifiedDrop, value: verified, setValue: setVerified, handle: props.handleMul, accessor: 'Insurance Verified', type: 'multiSelectGroup', resetValue: [currentPage()] },
      { name: 'Verification Owner', array: ownerDrop, value: owner, setValue: setOwner, handle: props.handleMul, accessor: 'Verification Owner', type: 'multiSelectGroup' },
      { name: 'MRN', value: mrn, setValue: setMrn, handle: props.handleMul, accessor: 'MRN', type: 'textField' },
      { name: 'Patient Count', value: count, setValue: setCount, type: 'label' },
    ]
  }

  //runs after each render
  useEffect(() => {
    // log('render vob rev una', verified);
    setCount(props.count);
  });

  //runs one time when session is changed or currentView
  useEffect(() => {
    if (!session.isLoggedIn || !session.sessionData) {
      return;
    }

    fetchData();
    clearAllFilters();
    // log('>>>', window.location.href);
  }, [session, props.currentView]);

  function currentPage() {
    // log('currentPage', props.currentView);

    if (props.currentView.toLocaleLowerCase().includes('vobreverify'))
      return 'Reverify'
    if (props.currentView.toLocaleLowerCase().includes('vobunable'))
      return 'Unable'

    return '';
  }

  const map = [
    { key: 'Insurance Verified', setValue: setVerifiedDrop },
    { key: 'Verification Owner', setValue: setOwnerDrop, blank: true },
  ];

  const fetchData = async () => {
    setLoading(true);

    let r3 = await userPermission(props.currentView);
    if (!r3.IsSuccess) {
      props.navigateOut();
      return;
    }

    if (props.originData.length === 0)
      await props.fetchOrganizations();

    try {
      // log('fetchData');

      map.forEach(async (it) => {
        let response = await getDropDownValues(it.key, props.currentView);
        if (response.IsSuccess) {
          let ar = (response.Data.Data.map((item: any) => item.Response));
          if (it.blank) ar = [...['Blank'], ...ar];

          it.setValue(ar);
        }
      });

      setResetVals();

    } catch (error) {
    } finally {
      setLoading(false);
      props.setRefreshBtnDisable(false);
    }
  };

  //pre selection
  function setResetVals() {
    let boxes = [...kindBoxes1.boxes];

    for (let i = 0; i < boxes.length; i++) {
      const el = boxes[i];
      if (el.resetValue)
        el.setValue(el.resetValue);
    }
  }

  function clearAllFilters() {
    //restart all filters 
    setLocationProp([]);
    setName([]);
    setOwner([]);
    setMrn([]);

    setResetVals();
    props.clearFilters();
  }

  return (
    <IntlProvider messages={{}} locale='de' defaultLocale='en'>
      <Box
        component='div'
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box>
          <Header
            clearAllFilters={clearAllFilters}
            clickOnRefresh={props.clickOnRefresh}
            clickOnExport={props.clickOnExport}
            refreshBtnDisable={props.refreshBtnDisable}
            refreshed={props.refreshed}
            title={`VOB ${currentPage()} Report`}
          />
          <FilterMenu filterContainer={<Fragment><BoxContainer  {...kindBoxes1} /></Fragment>} totalCount={count}></FilterMenu>
          <div><p></p></div>
          <CustomTable {...props.tablePars} />
        </Box>
      </Box>
    </IntlProvider>
  );
};

export default React.memo(VOBReverifyUnable);