import { CustomTable } from '../../components/table';
import { getDropDownValues } from '../../services/ReportAPIService';
import { IntlProvider } from 'react-intl';
import Box from '@mui/material/Box';
import BoxContainer from './components/boxContainer';
import React, { Fragment, useEffect } from 'react';
import { LoginState } from '../../store/slices/login/type';
import { getState } from '../../store';
import { userPermission } from '../../services/AuthAPIService';
import Header from './components/header';
import { orgParenting, log } from '../../utils/util';
import FilterMenu from './components/filterMenu';

export const OMPARequired = (props: any) => {
  //1st row
  const [locationProp, setLocationProp] = React.useState([]);
  const [managed, setManaged] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [insuranceReq, setInsuranceReq] = React.useState([]);

  //drops
  const [managedDrop, setManagedDrop] = React.useState(['']);
  const [insuranceReqDrop, setInsuranceReqDrop] = React.useState(['']);

  const [expanded, setExpanded] = React.useState(false);

  const [loading, setLoading] = React.useState(false);

  const session: LoginState = getState().loginReducer;

  let columns = [
    { label: 'Location', accessor: 'OrganizationName', sortable: true, width: '150px' },
    { label: 'Patient Name', accessor: 'FullName', sortable: true, width: '200px' },
    { label: 'MRN', accessor: 'MRN', sortable: true, width: '120px' },
    { label: 'DOB', accessor: 'DOB', sortable: true, width: '150px' },
    { label: 'Next Follow Up Visit Date', accessor: 'Next Follow Up Visit Date', sortable: true, width: '150px' },
    { label: 'Auth End Date', accessor: 'Auth End Date', sortable: true, width: '120px' },
    { label: 'Auth Visits Remaining', accessor: 'Remaining Visits', sortable: true, width: '120px' },
    { label: 'CustomerId', accessor: 'CustomerId', sortable: true, width: '120px' },
    { label: 'Username', accessor: 'Username', sortable: true, width: '300px', actionEvent: props.onClickCopy }
  ];

  // table
  props.tablePars.columns = columns;

  let kindBoxes1: any = {
    type: 'Joy',
    boxes: [
      { name: 'Location', array: orgParenting(), value: locationProp, setValue: setLocationProp, handle: props.handleParenting, accessor: 'OrganizationName', type: 'multiSelectGroup' },
      { name: 'Managed Patient Status', array: managedDrop, value: managed, setValue: setManaged, handle: props.handleMul, accessor: 'Patient Status', type: 'multiSelectGroup' },
      { name: 'Pre-Visit Insurance Requirements', array: insuranceReqDrop, value: insuranceReq, setValue: setInsuranceReq, handle: props.handleMul, accessor: 'Pre-Visit Insurance Requirements', type: 'checkMultiValueFilter' },
      { name: 'Patient Count', value: count, setValue: setCount, type: 'label' },
    ]
  }

  //runs after each render
  useEffect(() => {
    // log('render vob npa');
    setCount(props.count);
  });

  //runs one time when session is changed
  useEffect(() => {
    if (!session.isLoggedIn || !session.sessionData) {
      return;
    }

    fetchData();

  }, [session]);

  const map = [
    { key: 'Patient Status', setValue: setManagedDrop },
  ];

  const fetchData = async () => {
    setLoading(true);

    let r3 = await userPermission('om-parequired');
    if (!r3.IsSuccess) {
      props.navigateOut();
      return;
    }

    if (props.originData.length === 0)
      await props.fetchOrganizations();

    try {

      map.forEach(async (it) => {
        let response = await getDropDownValues(it.key, 'om-parequired');
        if (response.IsSuccess)
          it.setValue((response.Data.Data.map((item: any) => item.Response)));
      });

      setInsuranceReqDrop(['Pre-Auth BH',  'Pre-Auth MNT', 'Pre-Auth NPA', 'Pre-Auth RMR']);

      setResetVals();

    } catch (error) {
    } finally {
      setLoading(false);
      props.setRefreshBtnDisable(false);
    }
  };

  //pre selection
  function setResetVals() {
    let boxes = [...kindBoxes1.boxes];

    for (let i = 0; i < boxes.length; i++) {
      const el = boxes[i];
      if (el.resetValue)
        el.setValue(el.resetValue);
    }
  }

  function clearAllFilters() {
    //restart all filters 
    setLocationProp([]);
    setManaged([]);
    setInsuranceReq([]);

    setResetVals();
    props.clearFilters();
  }

  return (
    <IntlProvider messages={{}} locale='de' defaultLocale='en'>
      <Box
        component='div'
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box>
          <Header
            clearAllFilters={clearAllFilters}
            clickOnRefresh={props.clickOnRefresh}
            clickOnExport={props.clickOnExport}
            refreshBtnDisable={props.refreshBtnDisable}
            refreshed={props.refreshed}
            title={'PA Required'}
          />
          <FilterMenu filterContainer={<Fragment><BoxContainer  {...kindBoxes1} /></Fragment>} totalCount={count}></FilterMenu>
          <div><p></p></div>
          <CustomTable {...props.tablePars} />
        </Box>
      </Box>
    </IntlProvider>
  );
};

export default React.memo(OMPARequired);