import { Accordion, AccordionSummary, Typography, AccordionDetails } from "@mui/material";
import React, { useEffect } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircleIcon from '@mui/icons-material/Circle';

const FilterMenu = (props: any) => {

  useEffect(() => { }, [props]);

  const [expanded, setExpanded] = React.useState(false);

  return (
    <Accordion sx={{
      '&:before': {
        backgroundColor: 'transparent !important',
      }
    }} onChange={() => setExpanded(!expanded)} style={{ boxShadow: "2px 2px 5px 0px #00000033" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: '#0074BF' }} />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography sx={{
          fontSize: '18px',
          fontFamily: 'ProximaNovaSoftSemibold',
        }} component="span">{expanded ? 'Collapse Filters' : 'Expand Filters'} <span style={{ marginLeft: "8px", marginRight: "8px" }}><CircleIcon sx={{fontSize: '6px', marginBottom: '2px'}} /></span>{props.totalCount}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {props.filterContainer}
      </AccordionDetails>
    </Accordion>
  )
}

export default React.memo(FilterMenu);