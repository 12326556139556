import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import KindBox from './kindBox';
import { log, uuidv4 } from '../../../utils/util';

const BoxContainer = (props: any) => {
  // console.log(props);

  useEffect(() => { }, [props]);

  function renderBoxes(array: any) {
    // console.log('array', array);
    if (!array) return;
    let render = [];

    for (let i = 0; i < array.length; i++) {
      array[i].width = 93 / array.length;

      // dateRangePicker
      let founds = array.filter((item: any) => item.type == 'dateRangePicker');
      // log(founds);

      if (founds && founds.length > 0)
        array[i].width = 93 / (array.length + founds.length);

      if (array[i].type == 'dateRangePicker')
        array[i].width = (93 / (array.length + founds.length)) * 2;

      render.push(<KindBox key={uuidv4()} props={array[i]} />);
    }
    // console.log(render);

    return render;
  }

  return (
    <div key={uuidv4()}>
      <Box key={uuidv4()} style={{ display: 'flex', justifyContent: 'center', padding: 5, borderRadius: 5, width: '100%' }}>
        {renderBoxes(props.boxes)}
      </Box>
    </div>
  );
};

export default React.memo(BoxContainer);
