import { CustomTable } from '../../components/table';
import { getDropDownValues } from '../../services/ReportAPIService';
import { IntlProvider } from 'react-intl';
import Box from '@mui/material/Box';
import BoxContainer from './components/boxContainer';
import React, { Fragment, useEffect } from 'react';
import { LoginState } from '../../store/slices/login/type';
import { getState } from '../../store';
import { userPermission } from '../../services/AuthAPIService';
import Header from './components/header';
import { orgParenting, log } from '../../utils/util';
import dayjs from 'dayjs';
import FilterMenu from './components/filterMenu';

export const OMMissingBHPaperwork = (props: any) => {
  //1st row
  const [locationProp, setLocationProp] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [bhStatus, setBHStatus] = React.useState([]);
  const [bhIntakeForm, setBHIntakeForm] = React.useState([]);
  const [initialBHVisitAppointment, setInitialBHVisitAppointment] = React.useState([]);

  //drops
  const [creationDate, setCreationDate] = React.useState([]);
  const [bhStatusDrop, setBHStatusDrop] = React.useState(['']);
  const [bhIntakeFormDrop, setBHIntakeFormDrop] = React.useState(['']);
  const [initialBHVisitAppointmentDrop, setInitialBHVisitAppointmentDrop] = React.useState(['']);

  const [expanded, setExpanded] = React.useState(false);

  const [loading, setLoading] = React.useState(false);

  const session: LoginState = getState().loginReducer;

  let columns = [
    { label: 'Location', accessor: 'OrganizationName', sortable: true, width: '150px' },
    { label: 'Patient Name', accessor: 'FullName', sortable: true, width: '200px' },
    { label: 'MRN', accessor: 'MRN', sortable: true, width: '120px' },
    { label: 'DOB', accessor: 'DOB', sortable: true, width: '150px' },
    { label: 'Managed Patient Status', accessor: 'Patient Status', sortable: true, width: '150px' },
    { label: 'BH Status', accessor: 'BH Status', sortable: true, width: '120px' },
    { label: 'BH Follow Up Date', accessor: 'BH Follow Up Date', sortable: true, width: '150px' },
    { label: 'BH Follow Up Time', accessor: 'BH Follow Up Time', sortable: true, width: '120px' },
    { label: 'BH Initial Visit Date', accessor: 'BHInitialVisitDate', sortable: true, width: '150px' },
    { label: 'CustomerId', accessor: 'CustomerId', sortable: true, width: '120px' },
    { label: 'Username', accessor: 'Username', sortable: true, width: '300px', actionEvent: props.onClickCopy }
  ];

  // table
  props.tablePars.columns = columns;

  let kindBoxes1: any = {
    type: 'Joy',
    boxes: [
      { name: 'Location', array: orgParenting(), value: locationProp, setValue: setLocationProp, handle: props.handleParenting, accessor: 'OrganizationName', type: 'multiSelectGroup' },
      { name: 'BH Status', array: bhStatusDrop, value: bhStatus, setValue: setBHStatus, handle: props.handleMul, accessor: 'BH Status', type: 'multiSelectGroup' },
      { name: 'BH Intake Form', array: bhIntakeFormDrop, value: bhIntakeForm, setValue: setBHIntakeForm, handle: props.handleMul, accessor: 'BH Intake Form', type: 'multiSelectGroup' },
    ]
  }

  let kindBoxes2: any =
  {
    type: 'Joy',
    boxes: [
      { name: 'Initial BH Visit Appointment', array: initialBHVisitAppointmentDrop, value: initialBHVisitAppointment, setValue: setInitialBHVisitAppointment, handle: props.handleMul, accessor: 'Init BH Visit', type: 'multiSelectGroup' },
      {
        name: 'BH Initial Visit Date', value: creationDate, setValue: setCreationDate, handle: props.handleDate, accessor: 'BHInitialVisitDate', type: 'dateRangePicker',
        resetValue: [
          dayjs(Date.now()).format('YYYY/MM/DD'),
          dayjs(Date.now()).add(30, 'day').format('YYYY/MM/DD')
        ],
        toast: props.toast,
        clickOnRefresh: props.clickOnDateRangeGo,
        refreshBtnDisable: props.refreshBtnDisable,
      },
      { name: 'Patient Count', value: count, setValue: setCount, type: 'label' },
    ]
  }

  //runs after each render
  useEffect(() => {
    // log('render vob npa');
    setCount(props.count);
  });

  //runs one time when session is changed
  useEffect(() => {
    if (!session.isLoggedIn || !session.sessionData) {
      return;
    }

    fetchData();

  }, [session]);

  const fetchData = async () => {
    setLoading(true);

    let r3 = await userPermission('om-missingbhpaperwork');
    if (!r3.IsSuccess) {
      props.navigateOut();
      return;
    }

    if (props.originData.length === 0)
      await props.fetchOrganizations();

    try {

      setBHStatusDrop(['Yes']);
      setBHIntakeFormDrop(['Yes', 'No']);
      setInitialBHVisitAppointmentDrop(['Yes', 'No']);

      setResetVals();

    } catch (error) {
    } finally {
      setLoading(false);
      props.setRefreshBtnDisable(false);
    }
  };

  //pre selection
  function setResetVals() {
    let boxes = [...kindBoxes1.boxes];

    for (let i = 0; i < boxes.length; i++) {
      const el = boxes[i];
      if (el.resetValue)
        el.setValue(el.resetValue);
    }
  }

  function clearAllFilters() {
    //restart all filters 
    setLocationProp([]);
    setBHStatus([]);
    setBHIntakeForm([]);
    setInitialBHVisitAppointment([]);

    setResetVals();
    props.clearFilters();
  }

  return (
    <IntlProvider messages={{}} locale='de' defaultLocale='en'>
      <Box
        component='div'
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box>
          <Header
            clearAllFilters={clearAllFilters}
            clickOnRefresh={props.clickOnRefresh}
            clickOnExport={props.clickOnExport}
            refreshBtnDisable={props.refreshBtnDisable}
            refreshed={props.refreshed}
            title={'Missing BH Paperwork'}
          />
          <FilterMenu filterContainer={<Fragment><BoxContainer  {...kindBoxes1} /></Fragment>} totalCount={count}></FilterMenu>
          <div><p></p></div>
          <CustomTable {...props.tablePars} />
        </Box>
      </Box>
    </IntlProvider>
  );
};

export default React.memo(OMMissingBHPaperwork);