import { CustomTable } from '../../components/table';
import { getDropDownValues } from '../../services/ReportAPIService';
import { getState } from '../../store';
import { IntlProvider } from 'react-intl';
import { LoginState } from '../../store/slices/login/type';
import { orgParenting, log } from '../../utils/util';
import { userPermission } from '../../services/AuthAPIService';
import Box from '@mui/material/Box';
import BoxContainer from './components/boxContainer';
import Header from './components/header';
import React, { Fragment, useEffect } from 'react';
import FilterMenu from './components/filterMenu';

export const AccountLookup = (props: any) => {
  //1st row
  const [locationProp, setLocationProp] = React.useState([]);
  const [name, setName] = React.useState([]);
  const [mrn, setMrn] = React.useState([]);
  const [phone, setPhone] = React.useState([]);
  const [username, setUsername] = React.useState([]);
  const [managed, setManaged] = React.useState([]);

  const [count, setCount] = React.useState(0);

  //drops
  const [managedDrop, setManagedDrop] = React.useState(['']);

  const [expanded, setExpanded] = React.useState(false);

  const [loading, setLoading] = React.useState(false);
  const session: LoginState = getState().loginReducer;

  //done
  let columns: any = [
    { label: 'Created Date', accessor: 'ActionDate', sortable: true, width: '140px' },
    { label: 'Account Created By', accessor: 'AccountCreatedBy', sortable: true, width: '180px' },
    { label: 'Location', accessor: 'OrganizationName', sortable: true, width: '150px' },
    { label: 'Patient Name', accessor: 'FullName', sortable: true, width: '200px' },
    { label: 'DOB', accessor: 'DOB', sortable: true, width: '150px' },
    { label: 'MRN', accessor: 'MRN', sortable: true, width: '150px' },
    { label: 'Phone Number', accessor: 'ContactNo', sortable: true, width: '120px' },
    { label: 'Username', accessor: 'Username', sortable: true, width: '300px', actionEvent: props.onClickCopy }
  ];

  //table
  props.tablePars.columns = columns;

  //done
  let kindBoxes1 =
  {
    type: 'Joy',
    boxes: [
      { name: 'Location', array: orgParenting(), value: locationProp, setValue: setLocationProp, handle: props.handleParenting, accessor: 'OrganizationName', type: 'multiSelectGroup' },
      { name: 'Patient Name', value: name, setValue: setName, handle: props.handleMul, accessor: 'FullName', type: 'textField' },
      { name: 'MRN', value: mrn, setValue: setMrn, handle: props.handleMul, accessor: 'MRN', type: 'textField' },

      { name: 'Phone Number', value: phone, setValue: setPhone, handle: props.handleMul, accessor: 'ContactNo', type: 'textField' },
      { name: 'Username', value: username, setValue: setUsername, handle: props.handleMul, accessor: 'Username', type: 'textField' },
      { name: 'Count', value: count, setValue: setCount, type: 'label' },
    ]
  }

  //runs after each render
  useEffect(() => {
    // log('render vob rev una', verified);
    setCount(props.count);
  });

  //runs one time when session is changed
  useEffect(() => {
    if (!session.isLoggedIn || !session.sessionData) {
      return;
    }

    fetchData();
    clearAllFilters();
    // log('>>>', window.location.href);
  }, [session, props.currentView]);

  const fetchData = async () => {
    setLoading(true);

    let r3 = await userPermission(props.currentView);
    if (!r3.IsSuccess) {
      props.navigateOut();
      return;
    }

    if (props.originData.length === 0)
      await props.fetchOrganizations();

    try {
      // log('fetchData');

      setManagedDrop(['Yes', 'No']);
    } catch (error) {
    } finally {
      setLoading(false);
      props.setRefreshBtnDisable(false);
    }
  };


  function clearAllFilters() {
    //restart all filters 
    setLocationProp([]);
    setName([]);
    setMrn([]);
    setPhone([]);
    setUsername([]);
    setManaged([]);

    props.clearFilters();
  }

  return (
    <IntlProvider messages={{}} locale='de' defaultLocale='en'>
      <Box
        component='div'
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box>
          <Header
            clearAllFilters={clearAllFilters}
            clickOnRefresh={props.clickOnRefresh}
            clickOnExport={props.clickOnExport}
            refreshBtnDisable={props.refreshBtnDisable}
            refreshed={props.refreshed}
            title={`Patient Account Lookup`}
          />
          <FilterMenu filterContainer={<Fragment><BoxContainer  {...kindBoxes1} /></Fragment>} totalCount={count}></FilterMenu>
          <div><p></p></div>
          <CustomTable {...props.tablePars} />
        </Box>
      </Box>
    </IntlProvider>
  );
};

export default React.memo(AccountLookup);